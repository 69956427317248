<template>
  <div class="x-wrap">
    <div class="x-page-title">
      <h3>藏品列表</h3>
    </div>

    <div class="x-main">
      <!-- 筛选条件 -->
      <div class="x-q-panel">
        <el-row :gutter="20">
          <el-col :span="8">
            <div class="x-con-item">
              <label>查询：</label>
              <el-input
                placeholder="请输入藏品ID、藏品名称、藏品集或品牌方"
                v-model="query.content"
              ></el-input>
            </div>
          </el-col>
          <el-col :span="3">
            <el-button
              type="primary"
              class="x-q-btn"
              @click="
                query.pageIndex = 1;
                doQuery();
              "
              >筛选</el-button
            >
            <el-button type="text" @click="doQueryReset">重置</el-button>
          </el-col>
        </el-row>
      </div>

      <!-- 筛选结果 -->
      <div class="x-table-container">
        <div class="x-table-caption">
          <el-button
            icon="el-icon-refresh"
            type="primary"
            plain
            size="small"
            @click="doRefresh"
            >刷新</el-button
          >
        </div>

        <div
          class="x-table"
          v-loading="showLoading"
          element-loading-text="数据刷新中..."
        >
          <el-table :data="tableData" stripe style="width: 100%">
            <el-table-column prop="id" label="藏品ID" width="120">
            </el-table-column>
            <el-table-column prop="brandName" label="品牌方" align="center">
            </el-table-column>
            <el-table-column
              prop="collectionName"
              label="藏品集名称"
              width="150"
            ></el-table-column>
            <el-table-column
              prop="NFTName"
              label="藏品名称"
              width="150"
            ></el-table-column>
            <el-table-column prop="totalCount" label="发行数量" align="center">
            </el-table-column>
            <el-table-column prop="onlineCount" label="上架数量" align="center">
            </el-table-column>
            <el-table-column prop="sellCount" label="出售数量" align="center">
            </el-table-column>
            <el-table-column prop="airdropCount" label="空投数量" align="center">
            </el-table-column
            ><el-table-column prop="blindBoxCount" label="盲盒开出数量" align="center">
            </el-table-column>
            <el-table-column prop="composeCount" label="合成数量" align="center">
            </el-table-column>
            <el-table-column prop="NFTTime" label="发行时间" align="center">
              <template slot-scope="scope">
                <span v-text="scope.row.NFTTime || '-'"></span> </template
            ></el-table-column>
            <el-table-column label="操作" align="center" width="180">
              <template slot-scope="scope">
                <div class="flex-box-around">
                  <el-link
                    :underline="false"
                    type="primary"
                    @click="goUpdate(scope.row.id)"
                    >查看藏品详情</el-link
                  >
                </div>
              </template>
            </el-table-column>
          </el-table>

          <div class="x-pager right">
            <el-pagination
              background
              layout="sizes, prev, pager, next"
              :current-page.sync="query.pageIndex"
              :page-size="query.pageSize"
              :page-sizes="[10, 20, 30, 50]"
              :total="total"
              @size-change="setPageSize"
              @current-change="setPageIndex"
            ></el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { NftQuery } from "@/api/module/nft";

export default {
  name: "NftIndex",
  data() {
    return {
      showLoading: false,
      dialogshow: false,
      query: {
        content: "",
        pageIndex: 1,
        pageSize: 10,
      },
      total: 0,
      tableData: [],
    };
  },
  components: {
    nftPanel: () => import("./components/NftPanel.vue"),
  },
  created() {
    this.doQuery();
  },
  methods: {
    // 查询
    doQuery() {
      let query = this.setQueryParams();
      NftQuery(query).then((r) => {
        this.total = r.total;
        this.tableData = r.list;
      });
    },

    // 设置筛选
    setQueryParams() {
      let query = `?pagination=true&page=${this.query.pageIndex}&pageSize=${this.query.pageSize}&status=3`;
      if (this.query.content) {
        query += `&like=${this.query.content}`;
      }

      return query;
    },

    // 重置筛选
    doQueryReset() {
      this.query = {
        content: "",
        pageIndex: 1,
        pageSize: 10,
      };
      this.doQuery();
    },

    // 刷新
    doRefresh() {
      this.showLoading = true;
      this.doQueryReset();
      setTimeout(() => {
        this.$message({
          type: "success",
          message: "数据更新完毕！",
          duration: 1300,
          onClose: () => {
            this.showLoading = false;
          },
        });
      }, 1350);
    },

    // 删除
    doDelete(id) {
      const msg = "<span class='font-danger'>确认要删除本条数据吗？</span>";
      this.$alert(msg, "删除提示", {
        dangerouslyUseHTMLString: true,
        showCancelButton: true,
        confirmButtonText: "确定删除",
        center: true,
        callback: (action) => {
          if (action === "confirm") {
            NftRemove(id).then((r) => {
              if (r.code === 0) {
                this.doQuery();
                this.$message({
                  type: "success",
                  message: "删除成功",
                  duration: 1300,
                });
              }
            });
          }
        },
      });
    },

    // 去编辑
    goUpdate(id) {
      this.$router.push(`/nft/detail/${id}`);
    },

    // 设置分页
    setPageSize(pageSize) {
      this.query.pageIndex = 1;
      this.query.pageSize = pageSize;
      this.doQuery();
    },

    // 设置页码
    setPageIndex(pageIndex) {
      this.query.pageIndex = pageIndex;
      this.doQuery();
    },
  },
};
</script>



